<template>
    <section id="customer-show-page pt-16">
        <v-sheet>
            <!-- Services -->
            <h1 class="text-xl-h5 mb-5">Roles & Permissions</h1>
            <v-select v-model="role" flat outlined
                      item-text="name"
                      label="Select Role"
                      @change="fetchRolePermissions"
                      item-value="id"
                      :items="roles">
            </v-select>

            <div v-if="role">
                <div class="mb-10" v-for="(permission) in allPermissions">
                    <!--<h1 class="text-h4 text-capitalize mt-5">{{key}}</h1>-->
                    <v-switch v-model="permission.selected"
                              :label="permission.name"
                              color="primary"
                              @change="refreshPermissionsList"
                              :value="true"
                              hide-details>
                    </v-switch>
                </div>
                <v-btn v-if="can('roles.permissions.edit')"
                       color="success" @click="updateRolePermissions">
                    Save
                </v-btn>
            </div>
        </v-sheet>
    </section>
</template>


<script>
    import dictionaries from "../../../../api/modules/dictionaries";

    export default {
        name: 'RolePermissionsEditor',
        data: () => ({
            roles: [],
            role: null,
            perm: '',
            allPermissions: [],
        }),
        created: function () {
            this.fetchInitialData();
        },
        computed: {
            /** Returns Flat ID Array of Selected Role Permissions **/
            rolePermissions: function () {
                return this.allPermissions.filter(perm => {
                    return perm.selected;
                }).map(pem => pem.id)
            },
        },
        methods: {
            fetchInitialData: function () {
                dictionaries.fetchRolesDictionary().then(res => {
                    this.roles = res.data;
                });

                dictionaries.fetchPermissionsDictionary().then(res => {
                    this.allPermissions = res.data;
                })
            },
            fetchRolePermissions: function () {
                dictionaries.fetchRolePermissions(this.role).then(res => {
                    this.setRolePermissions(res.data);
                });
            },
            setRolePermissions: function (permissions) {
                this.allPermissions.map(permission => {
                    permission.selected = !!permissions.filter(rp => {
                        return rp.name === permission.name;
                    }).length;
                });

                this.refreshPermissionsList();
            },
            refreshPermissionsList: function () {
                this.allPermissions = Object.assign([], this.allPermissions);
            },
            updateRolePermissions: function () {
                dictionaries.updateRolePermissions(this.role, this.rolePermissions).then(res => {
                    this.role = null;

                  this.$notify({
                    title: 'Success',
                    text: 'Permissions Updated.',
                    type: 'success'
                  });
                  this.$router.push('/tasks');

                    this.flushPermissions();
                });
            },
        },
    }
</script>
